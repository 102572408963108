import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase/FirebaseConfig';
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, getDoc, updateDoc, addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase/FirebaseConfig';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [lofts, setLofts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);

      if (user) {
        const userDoc = doc(db, 'users', user.uid);
        const docSnap = await getDoc(userDoc);

        if (docSnap.exists()) {
          const userDocData = docSnap.data();

          // Verificar si el email está verificado antes de intentar actualizar Firestore
          if (user.emailVerified && !userDocData.emailVerified) {
            await updateDoc(userDoc, { emailVerified: true });
            console.log('Campo emailVerified actualizado en Firestore.');
            setUserData({ ...userDocData, emailVerified: true });
          } else {
            setUserData(userDocData);
          }
        } else {
          console.log('No existe tal documento');
          setUserData(null);
        }
      } else {
        setUserData(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const register = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = async () => {
    await signOut(auth);
    navigate('/');
  };

  const updateDisplayName = async (displayName) => {
    if (currentUser) {
      const userDoc = doc(db, 'users', currentUser.uid);
      await updateDoc(userDoc, { displayName });
      setUserData({ ...userData, displayName });
    }
  };
  const addLoft = async (nombre, ubicacion) => {
    if (currentUser) {
      const userDoc = doc(db, 'users', currentUser.uid);
      const loftsCollection = collection(userDoc, 'lofts');
      const newLoft = {
        nombre,
        ubicacion,
        numeroPalomas: 0,
      };
      const loftDocRef = await addDoc(loftsCollection, newLoft);

      // Actualizar el estado local con el nuevo loft
      setLofts([...lofts, { id: loftDocRef.id, ...newLoft }]);
    }
  };
  const value = {
    currentUser,
    userData,
    lofts,
    register,
    login,
    logout,
    updateDisplayName,
    addLoft,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
