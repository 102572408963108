import React, { useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import styled from 'styled-components';
import DashboardContentLayout from '../DashboardContentLayout';

const LoftsPage = ({ sectionName }) => {
    const { lofts, addLoft } = useAuth();

  const [newLoft, setNewLoft] = useState({
    nombre: '',
    ubicacion: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLoft({
      ...newLoft,
      [name]: value,
    });
  };

  const handleAddLoft = async (e) => {
    e.preventDefault();
    await addLoft(newLoft.nombre, newLoft.ubicacion);
    setNewLoft({ nombre: '', ubicacion: '' });
  };

  return (
    <DashboardContentLayout sectionName={sectionName}>
      <Container>
        <AddLoftForm onSubmit={handleAddLoft}>
          <h2>Añadir Nuevo Palomar</h2>
          <FormField>
            <label htmlFor="nombre">Nombre del Palomar</label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              value={newLoft.nombre}
              onChange={handleInputChange}
              required
            />
          </FormField>

          <FormField>
            <label htmlFor="ubicacion">Ubicación</label>
            <input
              type="text"
              id="ubicacion"
              name="ubicacion"
              value={newLoft.ubicacion}
              onChange={handleInputChange}
              required
            />
          </FormField>

          <SubmitButton type="submit">Añadir Palomar</SubmitButton>
        </AddLoftForm>

        <LoftsList>
          <h2>Lista de Palomares</h2>
          {lofts.map((loft) => (
            <LoftItem key={loft.id}>
              <h3>{loft.nombre}</h3>
              <p><strong>Ubicación:</strong> {loft.ubicacion}</p>
              <p><strong>Número de Palomas:</strong> {loft.numeroPalomas}</p>
            </LoftItem>
          ))}
        </LoftsList>
      </Container>
    </DashboardContentLayout>
  );
};

// Estilos
const Container = styled.div`
  display: flex;
  gap: 2rem;
  padding: 2rem 0;
`;

const LoftsList = styled.div`
  flex: 1;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 1rem;
  }
`;

const LoftItem = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #ddd;

  h3 {
    margin: 0;
    font-size: 1.2rem;
  }

  p {
    margin: 0.5rem 0;
  }
`;

const AddLoftForm = styled.form`
  flex: 1;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    margin-bottom: 1rem;
  }
`;

const FormField = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-sizing: border-box;
  }
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

export default LoftsPage;
