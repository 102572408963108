import React from 'react';
import styled from 'styled-components';
import { ReactComponent as UserIcon } from '../../imagenes/iconmonstr-user-20.svg';


const DropdownMenu = ({ isOpen, onToggle, onLogout, currentUser }) => {
    return (
        <DropdownContainer>
            <AvatarButton onClick={onToggle}>
                <UserAvatar />
            </AvatarButton>
            <MenuDropdown desplegado={isOpen.toString()}>
                {currentUser ? (
                    <>
                        <MenuItem href="/">Inicio</MenuItem>
                        <MenuItem href="/settings">Configuración</MenuItem>
                        <MenuItem onClick={onLogout}>Cerrar Sesión</MenuItem>
                    </>
                ) : (
                    <MenuItem href="/">Iniciar Sesión</MenuItem>
                )}
            </MenuDropdown>
        </DropdownContainer>
    );
};

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const AvatarButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 4rem;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const MenuDropdown = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  min-width: 180px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1150;
  display: ${(props) => (props.desplegado === 'true' ? 'block' : 'none')}; 
`;

const MenuItem = styled.a`
  display: block;
  padding: 0.75rem 1.5rem;
  color: #333;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const UserAvatar = styled(UserIcon)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  fill: white;
  object-fit: cover;
`;

export default DropdownMenu;
