import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import styled from 'styled-components';

const DashboardLayout = () => {
    return (
        <Container>
            <Sidebar>
            <StyledNavLink to="/dashboard" end>
                    Dashboard
                </StyledNavLink>
                <StyledNavLink to="/dashboard/lofts" >
                    Palomares
                </StyledNavLink>
                <StyledNavLink to="/dashboard/pigeons">
                    Palomas
                </StyledNavLink>
                <StyledNavLink to="/dashboard/categories" >
                    Categorías
                </StyledNavLink>
                {/* Agrega más elementos según sea necesario */}
            </Sidebar>
            <MainContent>
                <Outlet />
            </MainContent>
        </Container>
    );
};

// Estilos
const Container = styled.div`
  display: flex;
  margin-top:60px;
  min-height: 100vh;
`;

const Sidebar = styled.div`
  position: fixed;
  width: 250px;
  background-color: #2f3b52;
  height: 100%;
  padding: 10px;
  color: white;
  display: flex;
  flex-direction: column;
`;
const StyledNavLink = styled(NavLink)`
  margin: 10px 0;
  padding: 10px;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #3b495e;
  }

  &.active {
    background-color: #3b495e;
  }
`;

const SidebarItem = styled.div`
  margin: 10px 0;
  cursor: pointer;

  &:hover {
    background-color: #3b495e;
    padding: 10px;
    border-radius: 5px;
  }
`;

const MainContent = styled.div`
  flex: 1;
  margin-left: 250px;
  display: flex;
  flex-direction: column;
  background-color: #f1f3f6;
`;



const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const Card = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 200px;
  max-width: 300px;
`;

const CardTitle = styled.h2`
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
`;

const CardContent = styled.div`
  font-size: 16px;
  color: #666;
`;

export default DashboardLayout;
