import React from 'react';
import styled from 'styled-components';

const DashboardContentLayout = ({ sectionName, children }) => {
  return (
    <ContentContainer>
      <Header>
        <Title>{sectionName}</Title>
      </Header>
      <Content>
        {children}
      </Content>
    </ContentContainer>
  );
};

// Estilos
const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f1f3f6;
`;

const Header = styled.header`
  background-color: #fff;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 32px;
  padding-left: 20px;
  color: #333;
`;

const Content = styled.div`
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  flex: 1;
`;

export default DashboardContentLayout;
