import React from 'react';
import styled from 'styled-components';
import DashboardContentLayout from './../DashboardContentLayout';

const Pigeon = ({ sectionName }) => {
    return (
        <DashboardContentLayout sectionName={sectionName}>
            <h1>pigeons</h1>
        </DashboardContentLayout>
    );
}

export default Pigeon;