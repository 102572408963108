import React from 'react';
import styled from 'styled-components';
import DashboardContentLayout from './../DashboardContentLayout';


const Categories = ({sectionName}) => {
    return ( 
        <DashboardContentLayout sectionName={sectionName}>
        <h1>Categories</h1>
    </DashboardContentLayout>
    );

}

export default Categories;