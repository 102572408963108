import React from 'react';
import styled from 'styled-components';

const PigeonList = ({ pigeons }) => {
  return (
    <ListContainer>
      <ListTitle>Listado de Palomas</ListTitle>
      {pigeons.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>ID</TableHeader>
              <TableHeader>Nombre</TableHeader>
              <TableHeader>Raza</TableHeader>
              <TableHeader>Color</TableHeader>
              <TableHeader>Premios</TableHeader>
            </tr>
          </thead>
          <tbody>
            {pigeons.map((pigeon, index) => (
              <TableRow key={index}>
                <TableCell>{pigeon.id}</TableCell>
                <TableCell>{pigeon.name}</TableCell>
                <TableCell>{pigeon.breed}</TableCell>
                <TableCell>{pigeon.color}</TableCell>
                <TableCell>{pigeon.awards}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      ) : (
        <NoPigeonsMessage>No tienes palomas registradas.</NoPigeonsMessage>
      )}
    </ListContainer>
  );
};

// Estilos
const ListContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
`;

const ListTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  background-color: #f7f7f7;
  border-bottom: 2px solid #ddd;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const NoPigeonsMessage = styled.p`
  color: #666;
  font-size: 1.1rem;
  text-align: center;
`;

export default PigeonList;
