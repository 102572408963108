import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import DropdownMenu from './DropDownMenu';


const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { currentUser, logout } = useAuth();

    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLogout = async () => {
        try {
            await logout();
            setMenuOpen(false);
        } catch (error) {
            console.log('Error al cerrar sesión', error);
        }
    };


    return (
        <HeaderContainer>
            <Logo>FirePedigree</Logo>
            <DropdownMenu
                isOpen={menuOpen}
                onToggle={handleToggleMenu}
                onLogout={handleLogout}
                currentUser={currentUser}
                />
        </HeaderContainer>
    );
};

const HeaderContainer = styled.header`
  position: fixed;
  height: 60px;
  padding-left: 2rem;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const Logo = styled.h1`
  font-size: 1.5rem;
  margin: 0;
`;

export default Header;