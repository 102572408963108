import { Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import HomePage from './components/HomePage';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Dashboard from './components/user/Dashboard';
import VerifyEmailPage from './components/auth/VerifyEmailPage';
import { AuthProvider } from './contexts/AuthContext';
import Header from './components/header/Header';
import DashboardLayout from './components/user/DashboardLayout';
import PrivateRoute from './components/auth/PrivateRoute';
import LoftsPage from './components/user/pigeons/LoftsPage';
import Pigeon from './components/user/pigeons/Pigeons';
import Categories from './components/user/pigeons/Categories';

function App() {
  return (
    <>
      <AuthProvider>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify-email" element={<VerifyEmailPage />} />
          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute>
                <DashboardLayout />
              </PrivateRoute>
            }
          >
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
            <Route path="" element={<Dashboard sectionName="Panel Principal" />} />
            <Route path="lofts" element={<LoftsPage sectionName="Palomares" />} />
            <Route path="pigeons" element={<Pigeon sectionName="Palomas" />} />
            <Route path="categories" element={<Categories sectionName="Categorías" />} />
          </Route>
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
