import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Login from './auth/Login';
import styled from 'styled-components';

const HomePage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  },[currentUser, navigate]);

    return ( 
      <HomePageContainer>
        <ContentWrapper>
          <MainContent>
            <Title>Bienvenido a FirePedigree</Title>
            <Description>
              FirePedigree es la plataforma definitiva para gestionar y seguir el linaje de tus palomas.
              Con nuestra herramienta, puedes llevar un registro detallado de cada una de ellas, 
              gestionar sus datos y asegurarte de mantener una línea genética de calidad.
            </Description>
            {/* Puedes agregar más contenido o imágenes que describan tu producto aquí */}
          </MainContent>
          <AsideSection>
            <Login /> {/* El formulario de Login se renderiza aquí */}
          </AsideSection>
        </ContentWrapper>
      </HomePageContainer>
     );
}
 
const HomePageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const MainContent = styled.main`
  flex: 3;
  padding: 2rem;
`;

const AsideSection = styled.aside`
  background-color: #f0f0f0;
  border-radius: 8px 0 0 8px;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 60px); /* Aseguramos que no se superponga con el header */
  position: sticky;
  top: 60px; /* Inicia justo debajo del header */
  right: 0;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: top;
  z-index: 1;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
`;

const Description = styled.p`
  font-size: 1.25rem;
  color: #666;
  line-height: 1.6;
`;

export default HomePage;