import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/FirebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';
import styled from 'styled-components';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            setError(null);
            await login(email, password);
            navigate('/dashboard');
        } catch (error) {
            setError('Error al iniciar sesión');
        }
    }
    const handlePasswordReset = async () => {
        if (!email) {
            setError('Por favor, ingresa tu email para restablecer la contraseña');
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            setError('Correo de restablecimiento de contraseña enviado');
        } catch (error) {
            setError('Error al enviar el correo de restablecimiento');
        }
    };

    return (
        <>
            <Title>Iniciar Sesión</Title>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <form onSubmit={handleLogin}>
                <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                />
                <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Contraseña"
                    required
                />
                <Button type="submit">Iniciar Sesión</Button>
            </form>
            <Link href="#" onClick={handlePasswordReset}>¿Has olvidado tu contraseña?</Link>
            <Link href="/register">¿No tienes cuenta? Regístrate</Link>
        </>

    );
}


const Title = styled.h2`
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const Link = styled.a`
  text-align: center;
  display: block;
  margin-top: 1rem;
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 1rem;
  text-align: center;
`;

export default Login;