import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import DashboardContentLayout from './DashboardContentLayout';
import PigeonList from './pigeons/PigeonList';
import { FiEdit } from 'react-icons/fi';

const Dashboard = ({sectionName}) => {
  const { userData, updateDisplayName } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [displayName, setDisplayName] = useState(userData?.displayName || 'Colombófilo');

  useEffect(() => {
    if (userData && userData.displayName) {
      setDisplayName(userData.displayName);
    }
  }, [userData]);
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleBlur = async () => {
    setIsEditing(false);
    if (displayName !== userData?.displayName) {
      await updateDisplayName(displayName);  // Usar la función del contexto para actualizar el displayName
    }
  }

  return (
    <DashboardContentLayout sectionName={sectionName}>
      <ProfileCard>
      <ProfileTitleContainer>
        {isEditing ? (
          <ProfileInput
          type="text"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          onBlur={handleBlur}
          autoFocus
          />         
        ):
        <>
          <ProfileTitle>{displayName}</ProfileTitle>
          <EditIcon onClick={handleEditClick} />
        </>
}
</ProfileTitleContainer>
        
        <ProfileDetail>
          <strong>Email:</strong> {userData?.email}
        </ProfileDetail>
      </ProfileCard>

      <StatsContainer>
        <StatCard>
          <StatTitle>Número de Palomares</StatTitle>
          <StatValue>{userData?.numberOfPalomares || 0}</StatValue>
        </StatCard>
        <StatCard>
          <StatTitle>Número de Palomas</StatTitle>
          <StatValue>{userData?.numberOfPalomas || 0}</StatValue>
        </StatCard>
        <StatCard>
          <StatTitle>Número de Premios</StatTitle>
          <StatValue>{userData?.numberOfPremios || 0}</StatValue>
        </StatCard>
        <StatCard>
          <StatTitle>Categorías de Palomas</StatTitle>
          <StatValue>{userData?.categories?.length || 0}</StatValue>
        </StatCard>
      </StatsContainer>

      <PigeonList pigeons={userData?.pigeons || []} />
    </DashboardContentLayout>
  );
};

// Estilos
const ProfileCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;

const ProfileTitle = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
`;

const ProfileDetail = styled.p`
  font-size: 1.1rem;
  color: #666;
`;

const StatsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const StatCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  flex: 1;
  text-align: center;
`;

const StatTitle = styled.h3`
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const StatValue = styled.p`
  font-size: 2rem;
  color: #007bff;
  margin: 0;
`;
const ProfileInput = styled.input`
  font-size: 1.5rem;
  padding: 0.2rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
`;
const EditIcon = styled(FiEdit)`
  cursor: pointer;
  color: #007bff;
  font-size: 1.2rem;

  &:hover {
    color: #0056b3;
  }
`;
const ProfileTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default Dashboard;
