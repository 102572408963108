import React, {useState} from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import {doc, setDoc} from 'firebase/firestore';
import {db} from '../../firebase/FirebaseConfig';
import styled from 'styled-components';
import { sendEmailVerification } from 'firebase/auth';


const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [address, setAddress] = useState('');
  const [publicAddress, setPublicAddress] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const { register } = useAuth();
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null);

    // Verificar si las contraseñas coinciden
    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }

    // Requisitos de seguridad de la contraseña
    if (password.length < 8 || !/[A-Z]/.test(password) || !/[a-z]/.test(password) || !/[0-9]/.test(password)) {
      setError('La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una minúscula y un número');
      return;
    }

    try {
      const userCredential = await register(email, password);
      const user = userCredential.user;

      await sendEmailVerification(user); // Enviar correo de verificación
      // Guardar información adicional en Firestore
      await setDoc(doc(db, 'users', user.uid), {
        name,
        email,
        phone,
        country,
        address,
        publicAddress,
        emailVerified: false,
      });
      navigate('/verify-email'); // Redirige a la página principal después del registro
    } catch (error) {
        switch (error.code) {
            case 'auth/email-already-in-use':
                setError('El correo electrónico ya está registrado');
                break;
            case 'auth/weak-password':
                setError('La contraseña es débil');
                break;
            default:
                setError('Error al crear la cuenta');
                break;
        }
    }
  };

  return (
    <Container>
      <FormContainer>
        <Title>Regístrate</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <form onSubmit={handleRegister}>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nombre"
            required
          />
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <Input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Teléfono"
          />
          <Select
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
          >
            <option value="">Elige un país</option>
            {/* Añadir opciones de país */}
            <option value="spain">España</option>
            <option value='uk'>Reino Unido</option>
            <option value='usa'>Estados Unidos</option>
            <option value='germany'>Alemania</option>
          </Select>
          <Textarea
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Dirección"
            required
          />
         <CheckboxContainer>
            <Checkbox
              type="checkbox"
              id="publicAddress"
              checked={publicAddress}
              onChange={(e) => setPublicAddress(e.target.checked)}
            />
            <Label htmlFor="publicAddress">Mostrar dirección pública</Label>
          </CheckboxContainer>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Contraseña"
            required
          />
          <Input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirmar Contraseña"
            required
          />
          <Button type="submit">Registrarse</Button>
        </form>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
`;

const FormContainer = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 1rem;
  text-align: center;
`;
// Estilo para el contenedor del checkbox y la etiqueta
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

// Estilo para el checkbox
const Checkbox = styled.input`
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

// Estilo para la etiqueta del checkbox
const Label = styled.label`
  font-size: 1rem;
  color: #333;
  cursor: pointer;
`;

export default Register;