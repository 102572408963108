import React from 'react';

const VerifyEmailPage = () => {
  return (
    <div>
      <h1>Verifica tu correo electrónico</h1>
      <p>Por favor, revisa tu correo electrónico y haz clic en el enlace de verificación para activar tu cuenta.</p>
    </div>
  );
};

export default VerifyEmailPage;
